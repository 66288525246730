import { IPhoneNumber } from '@red/data-access';
import { IContactCustomerAndSupplier, ICountry, IGrossCommissionEarned, IResaleTransaction, IResaleBillingMetadata, IAddress, IEcdd } from '.';
import { EGstCategory } from '../enums/gst-category.enum';
import { EClientType, EGstType, EPaymentAdvice, EResalePaymentMethod } from '../enums/resale.enum';

export interface IResaleBilling {
  id?: number | null;
  resaleId?: number | null;
  resale?: IResaleTransaction;
  name?: string;
  paymentAdvice?: EPaymentAdvice;
  contactId?: number;
  contact?: IContactCustomerAndSupplier;
  remarks?: string;
  envDelivery?: boolean;
  attention?: string;
  billingDate?: string;
  companyProfit?: number;
  clientType?: EClientType;
  clientName?: string;
  clientNric?: string;
  clientContact?: IPhoneNumber;
  clientEmail?: string;
  clientCountryId?: number;
  clientCountry?: ICountry;
  gstCategoryId?: number;
  gstCategory?: string;
  transactionAmount?: number;
  valuationPrice?: number;
  percentage?: number;
  gstType?: EGstType;
  gstPercent?: number;
  grossComm: IGrossCommissionEarned;
  subAmount?: number;
  choosenAddressId?: number;
  ecddId?: number;
  ecdd?: IEcdd;
  metadata?: IResaleBillingMetadata;
  clientAddress?: IAddress;
  billingParty?: string;
  billingCountryId?: number;
  billingCountry?: ICountry;
  billingAddress?: IAddress;
  ecdds?: IEcdd[];
  ecddIds?: number[];
  paymentMethod?: EResalePaymentMethod;
  commission?: {
    type: 'percentage' | 'number';
    value: number;
    percentage: number | null;
    subAmount: number | null;
  };
  chequeDate?: string;
  invoiceOption?: {
    agent: {
      isChoosen: boolean;
      email?: string;
    };
    ecb: {
      isChoosen: boolean;
      email?: string;
    };
    client: {
      isChoosen: boolean;
      email?: string;
    };
    solicitor: {
      isChoosen: boolean;
      email?: string;
    };
    hardcopy: {
      isChoosen: boolean;
      email?: string;
    };
  };
  profitSharingAmount: number;
  ecddRef: string;
}
