import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutBaseComponent } from './layout.component';
import { EmptyLayoutModule } from './empty-layout/empty-layout.module';
import { ClassicLayoutModule } from './classic-layout/classic-layout.module';

const layoutModules = [
  // Empty
  EmptyLayoutModule,
  ClassicLayoutModule,
];

@NgModule({
  declarations: [LayoutBaseComponent],
  imports: [CommonModule, ...layoutModules],
  exports: [LayoutBaseComponent, ...layoutModules],
})
export class LayoutBaseModule {}
