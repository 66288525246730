export const confirmDialogTypeArr = ['success', 'danger', 'warning'] as const;
export type ConfirmDialogType = typeof confirmDialogTypeArr[number];
export interface ConfirmDialogData {
  type?: ConfirmDialogType;
  title?: string;
  description?: string;
  confirmLabel?: string | {
    text: string;
    link?: string;
  };
  dismissLabel?: string;
  hideDismissButton?: boolean;
}
