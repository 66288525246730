import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ClassicLayoutComponent } from './classic-layout.component';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RedVerticalNavigationModule } from '@red/components/navigation';
import { UserModule } from '../common/user/user.module';
import { GroupActionHeaderDirective } from '../group-action-header.directive';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [ClassicLayoutComponent, GroupActionHeaderDirective],
  imports: [CommonModule, RouterModule, MatSidenavModule, MatIconModule, MatButtonModule, PortalModule, RedVerticalNavigationModule, UserModule],
  exports: [ClassicLayoutComponent, GroupActionHeaderDirective],
})
export class ClassicLayoutModule {}
