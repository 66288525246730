import { ConfirmDialogComponent } from './confirm-dialog.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog-data.interface';

@Injectable()
export class ConfirmDialogService {

  constructor(
    private dialog:MatDialog
  ) { }

  open(data:ConfirmDialogData):MatDialogRef<ConfirmDialogComponent,MatDialogConfig<any>>{

    return this.dialog.open(ConfirmDialogComponent,{
      width: '400px',
      data
    })
  }
}
