import { BaseModel, Default, IsoString, PhoneNumber } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { EClientType, EGstType, EPaymentAdvice, EResalePaymentMethod } from '../enums/resale.enum';
import { IResaleBilling } from '../interfaces/resale-billing.interface';
import { GrossCommissionEarnedModel } from './gross-commission-earned.model';
import { ResaleTransactionModel } from './resale-transaction.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { CountryModel } from './country.model';
import { IResaleTransaction } from '../interfaces/resale-transaction.interface';
import { CLIENT_TYPE_OPTIONS, GST_TYPE_OPTIONS, PAYMENT_ADVICE_OPTIONS, PROJECT_GST_CATEGORIES, RESALE_PAYMENT_METHOD_OPTIONS } from '../data';
import { EGstCategory } from '../enums/gst-category.enum';
import { IResaleBillingMetadata } from '../interfaces';
import { AddressModel } from './address.model';
import { EcddModel } from './ecdd.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { ContactAddressModel } from './contact-address.model';

export class ResaleBillingModel extends BaseModel implements IResaleBilling {
  @Expose()
  id?: number;

  @Expose()
  attention?: string;

  @Expose()
  billingDate?: string;

  @Expose()
  @Type(() => PhoneNumber)
  clientContact?: PhoneNumber;

  @Expose()
  @Type(() => CountryModel)
  clientCountry?: CountryModel;

  @Expose()
  clientCountryId?: number;

  @Expose()
  clientEmail?: string;

  @Expose()
  clientName?: string;

  @Expose()
  clientNric?: string;

  @Expose()
  clientType?: EClientType;

  @Expose()
  companyProfit?: number;

  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact?: ContactCustomerAndSupplierModel;

  @Expose()
  contactId?: number;

  @Expose()
  envDelivery?: boolean;

  @Expose()
  @Default(GrossCommissionEarnedModel.createEmpty())
  @Type(() => GrossCommissionEarnedModel)
  grossComm!: GrossCommissionEarnedModel;

  @Expose()
  gstCategory?: EGstCategory;

  @Expose()
  gstCategoryId?: number;

  @Expose()
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  gstType?: EGstType;

  @Expose()
  name?: string;

  @Expose()
  paymentAdvice?: EPaymentAdvice;

  @Expose()
  @Type(() => Number)
  percentage?: number;

  @Expose()
  remarks?: string;

  @Expose()
  @Transform(({ value }) => (value ? ResaleTransactionModel.fromJson(value) : value))
  resale?: IResaleTransaction;

  @Expose()
  resaleId?: number;

  @Expose()
  transactionAmount?: number;

  @Expose()
  valuationPrice?: number;

  @Expose()
  subAmount?: number;

  @Expose()
  choosenAddress?: ContactAddressModel;

  @Expose()
  choosenAddressId?: number;

  @Expose()
  ecddId?: number;

  @Expose()
  @Type(() => EcddModel)
  ecdd?: EcddModel;

  @Expose()
  metadata?: IResaleBillingMetadata;

  @Expose()
  @Type(() => AddressModel)
  clientAddress?: AddressModel;

  @Expose()
  billingParty?: string;

  @Expose()
  billingCountryId?: number;

  @Expose()
  @Type(() => CountryModel)
  billingCountry?: CountryModel;

  @Expose()
  @Type(() => AddressModel)
  billingAddress?: AddressModel;

  @Expose()
  @Type(() => EcddModel)
  ecdds?: EcddModel[];

  @Expose()
  ecddIds?: number[];

  @Expose()
  paymentMethod?: EResalePaymentMethod;

  @Expose()
  @Transform(({ obj }) => {
    if (!obj.paymentMethod) return 'NO';

    switch (obj.paymentMethod) {
      case 'dropCheque':
        return 'YES (DC)';
      case 'bankTransfer':
        return 'YES (FT)';
      default:
        return 'NO';
    }
  })
  paymentMethodDisplay!: string;

  @Expose()
  @Default({
    type: 'number',
    value: 0,
    percentage: null,
    subAmount: null,
  })
  commission?: {
    type: 'percentage' | 'number';
    value: number;
    percentage: number | null;
    subAmount: number | null;
  };

  @Expose()
  chequeDate?: string;

  @Expose()
  invoiceOption?: {
    agent: {
      isChoosen: boolean;
      email?: string;
    };
    ecb: {
      isChoosen: boolean;
      email?: string;
    };
    client: {
      isChoosen: boolean;
      email?: string;
    };
    solicitor: {
      isChoosen: boolean;
      email?: string;
    };
    hardcopy: {
      isChoosen: boolean;
      email?: string;
    };
  };

  @Expose()
  profitSharingAmount!: number;

  @Expose()
  ecddRef!: string;

  get clientNames() {
    return this.clientName ? this.clientName.split(' ||| ') : [];
  }

  get paymentMethodDescription() {
    return this.paymentMethod ? RESALE_PAYMENT_METHOD_OPTIONS[this.paymentMethod] : null;
  }

  get paymentAdviceDescription() {
    return this.paymentAdvice ? PAYMENT_ADVICE_OPTIONS[this.paymentAdvice] : null;
  }

  get clientTypeDescription() {
    return this.clientType ? CLIENT_TYPE_OPTIONS[this.clientType] : null;
  }

  get gstTypeDescription() {
    return this.gstType ? GST_TYPE_OPTIONS[this.gstType] : null;
  }

  get gstCategoryDisplay() {
    return this.gstCategory ? PROJECT_GST_CATEGORIES.find(item => this.gstCategory === item.id) : null;
  }
}
