import { Component, OnInit, OnDestroy, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { catchError, debounceTime, filter, forkJoin, iif, map, Observable, of, pluck, Subject, Subscription, switchMap, take, takeUntil, tap } from 'rxjs';
import { ExceljsHttpLoader, EXCELJS_HTTP_LOADER } from '../exceljs-http-loader.service';
import { ExceljsCard, ExceljsCardQueryDto, ExceljsCardQueryParameterDto, ExceljsFileSupportedConfig, ExceljsParameter, ExceljsParameterVisualizationMatrixItemDataInput, ExceljsParameterVisualizationMatrixItemInput, ExceljsWorkBook, IExceljsParameter } from '../models';
import * as saveAs from 'file-saver';
import { ExceljsQueryParam, bindingDataParameter } from '../exceljs.utils';
import { cloneDeep, concat, isEmpty, isObject, flatten } from 'lodash-es';
import { ExceljsParameterService } from '../exceljs-parameter/exceljs-parameter.service';
import { Helper } from '@red/utils';
import { MatDrawer } from '@angular/material/sidenav';
import { ExceljsWorksheetComponent } from '../exceljs-worksheet/exceljs-worksheet.component';
import { ExceljsWorkbookComponent } from '../exceljs-workbook/exceljs-workbook.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ToastService } from '@shared/components/toast';
import { renderMatrices } from '../exceljs-parameter/exceljs-parameter.component';
import { HttpEventType } from '@angular/common/http';
@Component({
  selector: 'red-exceljs-question',
  templateUrl: './exceljs-question.component.html',
  styleUrls: ['./exceljs-question.component.scss'],
  providers: [ExceljsParameterService],
})
export class ExceljsQuestionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatDrawer) drawer!: MatDrawer;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  card?: ExceljsCard;
  workbook?: ExceljsWorkBook;
  loading = false;
  loadingDownload!: {
    [x: string]: boolean;
  };
  currentId?: string;
  currentQueryPayload?: ExceljsCardQueryDto;
  parameters: IExceljsParameter[] = [];
  countFilter = 0;
  isFilter = false;
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  private _tabSubscription = Subscription.EMPTY;
  currentPage = 0;
  length = 0;
  total = 0;
  limit = 10;
  page = 1;
  waitToFetch!: Subscription;
  cardName? = '';
  fileSupported?: ExceljsFileSupportedConfig[];
  worksheetSelected?: ExceljsWorksheetComponent;
  constructor(
    @Inject(EXCELJS_HTTP_LOADER) private apiService: ExceljsHttpLoader,
    private _location: Location,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _parameterService: ExceljsParameterService,
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this._fetch_2()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: value => {
          if (!value) return;

          const page = this._activatedRoute.snapshot.queryParamMap.get('page');
          this.page = page ? Number(page) : 1;
          this.card = value.card;
          this.workbook = value.workbook;
          this.cardName = value?.workbook?.name;
          this.mapFileSuppored(value.card?.fileSupported ?? []);
          if (!this.workbook) {
            return;
          }

          console.log('---ngOnInit---', this.workbook.meta);
          const meta = this.workbook.meta;
          this.total = meta?.total ?? (this.workbook.worksheets.length ? this.workbook.worksheets[0].rows.length : 0);
          this.limit = meta?.limit || 10;
          const maxCurrentLength = this.page * this.limit;
          this.length = meta ? Math.min(maxCurrentLength, this.total) : this.total;
          this.isFilter = value?.card?.parameterVisualization ? flatten(value?.card?.parameterVisualization?.matrices ?? []).findIndex(item => (typeof item === 'string' || typeof item?.hide === 'string' || !item?.hide)) !== -1 : !!(value?.card?.parameters && value?.card?.parameters?.length > 0)
        },
      });

    this._parameterService
      .connect()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(val => {
        if (!this.card?.parameters) {
          return;
        }
        this.page = 1;
        console.log('---_parameterService connect---', this.card.parameters, val);
        this._router.navigate([], {
          queryParams: ExceljsQueryParam.deserialize(this.card.parameters, val),
          relativeTo: this._activatedRoute,
        });
      });
  }

  ngAfterViewInit(): void {
    const queryParams = this._activatedRoute.snapshot.queryParams;
    this.page = queryParams['page'] || 1;
    this._router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(
        // debounceTime(300),
        switchMap(value => this._fetch_2()),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(value => {
        if (!value) return;


        console.log('_fetch', this.page);
        this.card = value?.card;
        this.workbook = value?.workbook;
        this.cardName = value?.workbook?.name;

        this.mapFileSuppored(value.card?.fileSupported ?? []);
        // this._fetch();
      });

    this._activatedRoute.params
      .pipe(
        map(params => params['id']),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(id => {
        if (id !== this.currentId && this.drawer) {
          this.drawer.close();
        }
        // this._fetch();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    this._tabSubscription.unsubscribe();
  }

  download(type: ExceljsFileSupportedConfig): void {
    if (!this.currentId) {
      return;
    }
    if (!this.card || this.workbook?.meta?.total === 0) {
      this._toastService.open({
        type: 'warning',
        title: 'Warning',
        messages: 'No record was found!',
      });
      return;
    }
    this.loadingDownload = {
      [type.type]: true
    };
    const payload = this.currentQueryPayload || {};
    console.log('type ====> ', type)
    if (type.stream) {
      this.apiService.export(this.currentId, payload, type).subscribe({
        next: (event) => {
          console.log(`event --> :`, event);
          if (!event) {
            return;
          }
          if (event.type === HttpEventType.DownloadProgress) {
            const progress = Math.round(100 * event.loaded / event.total);
            console.log(`Download progress: ${progress}%`);
          } else if (event.type === HttpEventType.Response) {
            const blob = event.body as Blob;
            saveAs(blob, `${this.cardName}.${type.type}`);
            this.loadingDownload = {
              [type.type]: false
            };

          }
        },
        error: (error) => {
          this.loadingDownload = {
            [type.type]: false
          };
          this._toastService.open({
            type: 'danger',
            title: 'Error',
            messages: error.message ?? 'Something went wrong',
          });
        }
      });

    } else {
      this.apiService.export(this.currentId, payload, type).subscribe({
        next: (res) => {

          const blob = new Blob([res], { type: type.type === 'xlsx' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf' });
          saveAs(blob, `${this.cardName}.${type.type}`);
          this.loadingDownload = {
            [type.type]: false
          };
        },
        error: (error) => {
          this.loadingDownload = {
            [type.type]: false
          };
          this._toastService.open({
            type: 'danger',
            title: 'Error',
            messages: error.message ?? 'Something went wrong',
          });
        }
      });
    }

  }

  downloadPdf(): void {
    if (!this.currentId) {
      return;
    }
    const payload = this.currentQueryPayload || {};
    this.apiService.export(this.currentId, payload, 'pdf').subscribe(res => {
      const blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, `${this.cardName}.pdf`);
    });
  }
  onPageChange(event: PageEvent): void {
    console.log('onPageChange', event);
    this.page = event.pageIndex + 1;
    if (!this.card || !this.worksheetSelected) {
      return;
    }
    this._router.navigate([], {
      queryParams: { page: this.page },
      relativeTo: this._activatedRoute,
      queryParamsHandling: 'merge',
    });
    // return;
    // const payload = this.generatePayload(this.page);
    // this.loading = true;
    // this.queryBy(this.card.code, payload).subscribe(data => {
    //   if (!this.workbook) {
    //     return;
    //   }
    //   this.workbook.worksheets[0].rows = data.worksheets[0].rows;
    //   // this.workbook
    //   if (this.worksheetSelected) {
    //     this.worksheetSelected.table.renderTable();
    //   }

    //   this.loading = false;
    // });
  }
  subscribleToTabScroll(selected: ExceljsWorksheetComponent): void {
    if (!this.card) {
      return;
    }

    if (!this.workbook) {
      return;
    }
    if (this.card.parameters.every(parameter => parameter.slug !== 'page')) {
      return;
    }
    if (this.waitToFetch) {
      this.waitToFetch.unsubscribe();
    }
    if (this._tabSubscription) {
      this._tabSubscription.unsubscribe();
    }
    this.worksheetSelected = selected;
    this.switchMode(this.card.configs.paging);
    // console.log('subscribleToTabScroll -->', selected);
  }
  switchMode(isPaging: boolean) {
    const selected = this.worksheetSelected;
    if (!selected) {
      return;
    }
    if (isPaging) {
      this._tabSubscription = this.paginator.page.subscribe(data => this.onPageChange(data));
    } else {
      if (selected.table.scrollController) {
        this._tabSubscription = selected.table.scrollController.srollToEnd
          .pipe(
            filter(data => {
              console.log('subscribleToTabScroll -->', data.dataLength, { total: this.total, currentPage: this.currentPage, nextPage: this.page });
              // if (data.dataLength < this.total) {
              // if (index + 5 > this.length && this.length < this.total) {
              const currentPage = Math.ceil(data.range.end / this.limit);
              // console.log({ currentPage, previous: this.page });
              if (this.currentPage === this.page) {
                return true;
              }
              // }
              return false;
            }),
            map(() => {
              this.page += 1;
              return this.page;
            })
          )
          .subscribe(nextPage => {
            // val.set('this.card', this.card);
            if (!this.card) {
              return;
            }
            const payload = this.generatePayload(nextPage);
            this.loading = true;
            this.queryBy(this.card.code, payload).subscribe(data => {
              if (!this.workbook) {
                return;
              }
              this.workbook.worksheets[0].rows = concat(this.workbook.worksheets[0].rows, data.worksheets[0].rows);
              // this.workbook
              console.log(' this.workbook.worksheets[0].rows ', this.workbook.worksheets[0].rows.length);
              selected.table.renderTable();
              this.loading = false;
            });
          });
      }
    }
  }
  generatePayload(page: number): ExceljsCardQueryDto {
    const val = this._parameterService.getImperativeState();
    const parameters = this.card?.parameters || [];
    val.set('page', page);
    const queryParams = ExceljsQueryParam.deserialize(parameters, val);
    console.log('subscribleToTabScroll queryParams-->', queryParams);
    const normolizedQueryParams = ExceljsQueryParam.serialize(parameters, queryParams);
    const normalizedParameters = bindingDataParameter(parameters, normolizedQueryParams);
    const payload = ExceljsCardQueryDto.fromJson({ parameters: normalizedParameters });
    return payload;
  }
  private _fetch(): void {
    const id = this._activatedRoute.snapshot.paramMap.get('id');
    // const id = idRaw && parseFloat(idRaw);
    if (!id) {
      return;
    }
    if (id === this.currentId && this.card) {
      const queryParams = this._activatedRoute.snapshot.queryParams;
      const normolizedQueryParams = ExceljsQueryParam.serialize(this.card.parameters, queryParams);

      const parameters = bindingDataParameter(this.card.parameters, normolizedQueryParams);
      const payload = ExceljsCardQueryDto.fromJson({ parameters });
      this.currentQueryPayload = payload;
      this.parameters = cloneDeep(parameters);
      // console.log('this.parameters loaded', this.parameters);
      this.updateQueryCounter(payload);
      this.loading = true;
      this.apiService
        .query(id, payload)
        .pipe(catchError(error => of(undefined)))
        .subscribe(query => {
          this.workbook = query;
          this.loading = false;
        });
      return;
    }
    const routeData = this._activatedRoute.snapshot.data;
    const cardInstance = cloneDeep(routeData['card']);
    // this.drawer.close();
    this.currentId = id;
    this.loading = true;
    iif(() => cardInstance instanceof ExceljsCard && cardInstance.code === id, of(cardInstance), this.apiService.getCard(id))
      .pipe(
        switchMap((card: ExceljsCard) => {
          const queryParams = this._activatedRoute.snapshot.queryParams;

          const normolizedQueryParams = ExceljsQueryParam.serialize(card.parameters, queryParams);

          const parameters = bindingDataParameter(card.parameters, normolizedQueryParams);
          const payload = ExceljsCardQueryDto.fromJson({ parameters });

          // const parameters = bindingDataParameter(card.parameters, queryParams);
          // const payload = ExceljsCardQueryDto.fromJson({ parameters });
          this.currentQueryPayload = payload;
          this.parameters = cloneDeep(parameters);
          this.updateQueryCounter(payload);
          return forkJoin({
            card: of(card),
            workbook: this.queryBy(id, payload).pipe(catchError(error => of(undefined))),
          });
        })
      )
      .subscribe(({ card, workbook }) => {
        this.card = card;
        this.workbook = workbook;
        this.loading = false;
        this.mapFileSuppored(card.fileSupported ?? []);
      });
  }

  private _fetch_2(): Observable<{
    card?: ExceljsCard;
    workbook?: ExceljsWorkBook;
  } | null> {
    const id = this._activatedRoute.snapshot.paramMap.get('id');
    if (!id) return of(null);

    if (id === this.currentId && this.card) {
      const queryParams = this._activatedRoute.snapshot.queryParams;
      const normolizedQueryParams = ExceljsQueryParam.serialize(this.card.parameters, queryParams);

      const parameters = bindingDataParameter(this.card.parameters, normolizedQueryParams);
      const payload = ExceljsCardQueryDto.fromJson({ parameters });
      this.currentQueryPayload = payload;
      this.parameters = cloneDeep(parameters);
      this.loading = true;
      this.updateQueryCounter(payload, this.card, cloneDeep(parameters));

      return this.queryBy(id, payload).pipe(
        catchError(error => of(undefined)),
        map(value => {
          return {
            card: this.card,
            workbook: value,
          };
        }),
        tap(() => (this.loading = false))
      );
    }

    this.currentId = id;
    this.loading = true;
    const routeData = this._activatedRoute.snapshot.data;
    const cardInstance = cloneDeep(routeData['card']);

    return iif(() => cardInstance instanceof ExceljsCard && cardInstance.code === id, of(cardInstance), this.apiService.getCard(id)).pipe(
      switchMap((card: ExceljsCard) => {
        const queryParams = this._activatedRoute.snapshot.queryParams;
        const normolizedQueryParams = ExceljsQueryParam.serialize(card.parameters, queryParams);
        const parameters = bindingDataParameter(card.parameters, normolizedQueryParams);
        const payload = ExceljsCardQueryDto.fromJson({ parameters });
        this.currentQueryPayload = payload;
        this.parameters = cloneDeep(parameters);
        this.updateQueryCounter(payload, card, cloneDeep(parameters));

        return forkJoin({
          card: of(card),
          workbook: this.queryBy(id, payload).pipe(catchError(error => of(undefined))),
        });
      }),
      tap(() => (this.loading = false))
    );
  }
  private queryBy(id: string, payload: ExceljsCardQueryDto): Observable<ExceljsWorkBook> {
    return this.apiService.query(id, payload).pipe(
      tap(data => {
        if (data.meta) {
          this.currentPage = data.meta.page;
          this.page = data.meta.page;
          this.total = data.meta.total;
        }
        if (data.name) {
          this.cardName = data.name;
        }
      })
    );
  }
  updateQueryCounter(dto: ExceljsCardQueryDto, card?: ExceljsCard, parameters?: IExceljsParameter[]): void {
    const parameterMap: Record<string, any> = (parameters || []).reduce((acc: Record<string, any>, param: ExceljsParameter) => {
      acc[param.slug] = param.value;
      return acc;
    }, {});
    const matrices = renderMatrices(cloneDeep(card), parameterMap);
    const matriesFlattern = new Map(
      flatten(matrices ?? [])?.map(c => [c?.slug, c])
    );
    this.countFilter = [...matriesFlattern.values()]?.filter(matrix => !matrix.hide).reduce((count, matrix) => {
      const parameter = dto.parameters.find(item => item.slug === matrix.slug) as ExceljsCardQueryParameterDto;
      if (isObject(parameter.value)) {
        if (!isEmpty(Helper.removeEmpty(parameter.value))) {
          count += 1;
        }
      } else if (parameter.value) {
        count += 1;
      }
      return count;
    }, 0);
  }

  back(): void {
    this._location.back();
  }

  mapFileSuppored(fileSupported: ExceljsFileSupportedConfig[]) {
    if (fileSupported?.length > 0) {
      this.loadingDownload = fileSupported.reduce((a, v) => ({ ...a, [v.type]: false }), {})
      this.fileSupported = fileSupported
    }
  }
}

function convertMapToObject(metricArguments: Map<string, any>): Record<string, any> {
  const newObject: Record<string, any> = {};
  for (const [key, value] of metricArguments) {
    newObject[key] = value;
  }
  return newObject;
}
