import { BaseModel } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { IProfitSplitPart, ISplitPart } from '../interfaces';
import { DesignationModel } from './designation.model';

// import { PersonalParticularModel } from './personal-particular.model';

export class SplitPartModel extends BaseModel implements ISplitPart {
  @Expose()
  level!: number;

  @Expose()
  name?: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  salesperson!: {
    id: number;
    businessName: string;
    nricName: string;
    ceaRegNo: string;
  };

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  value!: number;

  @Expose()
  defaultValue?: number;
}

export class ProfitSplitPartModel extends BaseModel implements IProfitSplitPart {
  @Expose()
  level!: number;

  @Expose()
  name?: string;

  @Expose()
  salespersonId!: number;

  @Expose()
  salesperson!: {
    id: number;
    businessName: string;
    nricName: string;
    ceaRegNo: string;
  };

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  value!: number;

  @Expose()
  defaultValue?: number;

  @Expose()
  @Type(() => DesignationModel)
  designation?: DesignationModel;

  @Expose()
  designationId?: number;
}
