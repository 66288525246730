<div mat-dialog-title align="end">
  <button mat-icon-button mat-dialog-close><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content>
  <div class="flex flex-row justify-center">
    <mat-icon class="icon-size-16" svgIcon="confirm_{{ data.type }}"></mat-icon>
  </div>
  <div class="text-h3 font-bold text-theme-navy-blue text-center">{{ data.title }}</div>
  <p class="text-b1 font-normal text-theme-gray text-center mb-6 whitespace-pre-line">{{ data.description }}</p>
</div>
<div mat-dialog-actions align="center">
  <button *ngIf="!data.hideDismissButton" mat-button (click)="dimiss()">{{ data.dismissLabel ??  'Cancel'}}</button>
  <button *ngIf="!confirmData.link"  mat-flat-button color="primary" (click)="confirm()">{{ data.confirmLabel ?? 'OK' }}</button>
  <a *ngIf="confirmData.link" [href]="confirmData.link" target="_blank" mat-flat-button color="primary" 
  rel="noopener noreferrer" mat-dialog-close>{{confirmData.text}}</a>
</div>
