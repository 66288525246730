import { ROUTE } from '@agent-portal/constants';
import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@id/ui/layout';
import { RedirectGuard, TOKEN_AUTH_NAME, TokenAuthGuard, provideRedirectGuard } from '@shared/features/auth';
import { environment } from '../environments/environment';

const routerConfig: ExtraOptions = {
  // preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabledBlocking',
};
const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: ROUTE.DASHBOARD,
  // },
  // { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: ROUTE.DASHBOARD },
  {
    path: '',
    pathMatch: 'full',
    providers: [
      provideRedirectGuard(environment.authConfig.defaultAppUrl)
    ],
    canActivate: [
      RedirectGuard
    ],
    component: LayoutComponent
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: ROUTE.AUTH.LOGIN,
        loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.SignInModule),
      },
      {
        path: ROUTE.AUTH.FORGOT_ACCOUNT,
        loadChildren: () => import('./modules/auth/forgot-account/forgot-account.module').then(m => m.ForgotAccountModule),
      },
      {
        path: ROUTE.AUTH.RESET_PASSWORD,
        providers: [
          {
            provide: TOKEN_AUTH_NAME,
            useValue: 'resetToken',
          },
          TokenAuthGuard,
        ],
        canActivate: [TokenAuthGuard],
        loadChildren: () => import('./modules/auth/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      },
    ],
  },
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [

      // Error
      {
        path: 'error',
        children: [
          {
            path: '403',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error403Module),
          },
          {
            path: '404',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error404Module),
          },
          {
            path: '500',
            loadChildren: () => import('@shared/components/error-page').then(m => m.Error500Module),
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
