import { Injectable, inject } from '@angular/core';
import { LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { AuthApiLoader } from '@shared/features/auth';
import { Observable } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { TokenType } from '@shared/data-access/types';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AuthApiLoader {
  private _authApiService = inject(AuthApiService);

  /**
   * Sign in
   *
   * @param credentials
   */
  override signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    return this._authApiService.signIn(credentials);
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  override refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto> {
    return this._authApiService.refreshToken(refreshToken);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this._authApiService.resetPassword(data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(data: any): Observable<any> {
    return this._authApiService.forgotPassword(data);
  }
  /**
   * check token is valid or not
   *
   * @param password
   */
  isTokenValid(data: { type: TokenType; token: string }): Observable<any> {
    return this._authApiService.isTokenValid(data);
  }
  getMe(): Observable<any> {
    return this._authApiService.getMe();
  }
}
