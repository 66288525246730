import { ClassProvider, InjectionToken, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { NavigationGroup } from './navigation.types';

export const NAVIGATION_SERVICE = new InjectionToken<NavigationBaseService>('NAVIGATION_SERVICE');

export abstract class NavigationBaseService {
  protected _navigation: ReplaySubject<NavigationGroup> = new ReplaySubject<NavigationGroup>(1);
  /**
   * Constructor
   */
  constructor(protected _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<NavigationGroup> {
    return this._navigation.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  abstract get(): Observable<NavigationGroup>;
}

export const getNavigationService = (instance: Type<NavigationBaseService>): ClassProvider => ({
  provide: NAVIGATION_SERVICE,
  useClass: instance,
  multi: false,
});
