import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
})
export class ConfigModule {
  static forRoot(configs?: { load: Provider[] }): ModuleWithProviders<ConfigModule> {
    const providers = configs?.load || [];
    return {
      ngModule: ConfigModule,
      providers,
    };
  }
}
