import { BaseModel, Default, EndOfDate, FormattedDateString, StartOfDate } from '@red/data-access';
import { Expose, Type } from 'class-transformer';
import { EManagementCommissionActivePd, EManagementCommissionPdRole, EManagementCommissionStatus } from '../enums';
import { AppointmentTypeModel, ManagementCommissionPdModel } from '../models';

export class ManagementCommissionDataUpdateDto extends BaseModel {
  @Expose()
  isActive!: boolean;

  @Expose()
  name!: string;

  @Expose()
  code!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @EndOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose()
  remarks!: string;
}

export class ManagementCommissionCreateDto extends BaseModel {
  @Expose()
  @Default('')
  code!: string;

  @Expose()
  @Default('')
  name!: string;

  @Expose()
  @Default(true)
  isActive!: boolean;

  @Expose()
  remarks!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  startDate!: string;

  @Expose()
  @Type(() => Date)
  @StartOfDate()
  @FormattedDateString()
  endDate!: string;

  @Expose()
  @Default([])
  @Type(() => ManagementCommissionPdModel)
  pdOverride!: ManagementCommissionPdModel[];

  @Expose()
  @Default([])
  @Type(() => ManagementCommissionPdModel)
  pdReferral!: ManagementCommissionPdModel[];

  @Expose()
  @Default([])
  @Type(() => ManagementCommissionPdModel)
  pdManagement!: ManagementCommissionPdModel[];

  @Expose()
  @Default(true)
  activePdOverride!: boolean;

  @Expose()
  @Default(true)
  activePdReferal!: boolean;

  @Expose()
  @Default(true)
  activePdManagement!: boolean;
}

export class ManagementCommissionUpdateDto extends BaseModel {
  @Expose()
  group!: string;

  @Expose()
  @Type(() => ManagementCommissionDataUpdateDto)
  data!: ManagementCommissionDataUpdateDto;
}

export class ManagementCommissionPdCreateDto extends BaseModel {
  @Expose()
  pdRole!: EManagementCommissionPdRole;

  @Expose()
  @Type(() => Number)
  managementCommId!: number;

  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  additional!: number;
}

export class ManagementCommissionPdUpdateDto extends BaseModel {
  @Expose()
  @Type(() => Number)
  appointmentTypeId!: number;

  @Expose()
  @Type(() => String)
  appTypeCode!: string;

  @Expose()
  @Type(() => AppointmentTypeModel)
  appointmentType!: AppointmentTypeModel;

  @Expose()
  @Type(() => Number)
  percentage!: number;

  @Expose()
  @Type(() => Number)
  additional!: number;
}

export class ManagementCommissionStatusUpdateDto extends BaseModel {
  @Expose()
  action!: EManagementCommissionStatus;

  @Expose()
  data!: {
    approverId?: number;
    reason: string;
  };
}

export class ManagementCommissionUpdateActivePd extends BaseModel implements Record<EManagementCommissionActivePd, boolean> {
  @Expose()
  activePdManagement!: boolean;

  @Expose()
  activePdOverride!: boolean;

  @Expose()
  activePdReferal!: boolean;
}
