import { AuthApiService } from '@agent-portal/apis';
import { Injectable } from '@angular/core';
import { UserAccountModel } from '@shared/data-access/models';
import { UserAdapter, UserAdapterBase } from '@shared/features/user';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends UserAdapterBase<UserAccountModel> {
  constructor(private authApiService: AuthApiService) {
    super();
  }
  override get(): Observable<UserAccountModel> {
    return this.authApiService.getMe().pipe(
      tap(user => {
        console.log('getMe --> ', user)
        this._user.next(user);
      })
    );
  }
}
