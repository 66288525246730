import { HTTP_ERROR } from '@agent-portal/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDTO, ApiService } from '@red/api';
import { LoginDto, LoginResponseDto, RefreshTokenResponseDto } from '@shared/data-access/dto';
import { UserAccountModel } from '@shared/data-access/models';
import { TokenType } from '@shared/data-access/types';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  static ROOT_POINT = 'auth';
  static REFRESH_TOKEN = 'auth/access-token';
  static RESET_PASSWORD = 'auth/reset-password';
  static FORGOT_PASSWORD = 'auth/forgot-password';
  static CHECK_TOKEN = 'auth/check-token';

  constructor(private apiService: ApiService, private httpClient: HttpClient) { }

  getMe(): Observable<UserAccountModel> {
    return this.httpClient.get<ApiDTO>('/me').pipe(map(res => UserAccountModel.fromJson(res.data)));
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: LoginDto): Observable<LoginResponseDto> {
    // return this.apiService.post(AuthApiService.ROOT_POINT, credentials);
    return this.httpClient.post<ApiDTO>('/login', credentials).pipe(map(res => res.data as any));
  }

  /**
   * Refresh token
   *
   * @param credentials
   */
  refreshToken(refreshToken: string): Observable<RefreshTokenResponseDto> {
    return this.httpClient.post<ApiDTO>('/refresh', { refreshToken }).pipe(map(res => res.data as any));
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(data: { password: string }): Observable<any> {
    return this.apiService.post(AuthApiService.RESET_PASSWORD, data);
  }

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(data: any): Observable<any> {
    return this.apiService.post(AuthApiService.FORGOT_PASSWORD, data);
  }
  isTokenValid(data: { type: TokenType; token: string }): Observable<any> {
    return this.apiService.post(AuthApiService.CHECK_TOKEN, data, {
      exposeHeaders: {
        [HTTP_ERROR.HTTP_STATUS_CODE_EXCLUDE]: ['404'],
      },
    });
  }
}
