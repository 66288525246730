import { APP_CONFIG, IAppConfig } from '@agent-portal/config';
import { ROUTE } from '@agent-portal/constants';
import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, ViewEncapsulation, inject } from '@angular/core';
import { Confirmable } from '@shared/components/confirm-dialog';
import { LayoutBaseComponent } from '@shared/components/layout';
import { RedocConnectService } from '@shared/components/redoc-connect';
import { AuthProvider, AuthUtils } from '@shared/features/auth';
import { Subject, Subscription, filter, of, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'id-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent extends LayoutBaseComponent implements OnDestroy {
  private _authService = inject(AuthProvider);
  private _appConfig: IAppConfig = inject(APP_CONFIG);
  private _unsubscribeAll$ = new Subject<void>();
  tokenReceivedSubscription?: Subscription;

  ngOnDestroy(): void {
    this._unsubscribeAll$.next();
    this._unsubscribeAll$.unsubscribe();
    this.tokenReceivedSubscription?.unsubscribe();
  }
}
