import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NxWelcomeComponent } from './nx-welcome.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RedLayoutConfigModule } from '@red/components/layout-config';
import { appConfig, getNavigationService } from '@shared/components/layout';
import { RedIconModule } from '@red/components/icon';
import { CUSTOME_ICON_DATA } from '@agent-portal/core';
import { RedApiModule } from '@red/api';
import { environment } from '../environments/environment';
import { ConfigModule, getAppConfigProvider, getDateTimeAdapterProvider, getDateTimeConfigProvider } from '@agent-portal/config';
import { useUserAdapterService } from '@shared/features/user';
import { UserService } from '@agent-portal/user/services';
import { NavigationPermissionService } from '@agent-portal/ui/navigation';
import { ROUTE, dateTimeConfig } from '@agent-portal/constants';
import { APP_DATE_FORMATS, DateFnsDateAdapter } from '@shared/core';
import { AuthModule, useUathApiLoader } from '@shared/features/auth';
import { forcedLogoutFn } from '@agent-portal/auth/data-access';
import { TranslocoModuleCore } from '@shared/components/transloco';
import { RedMediaWatcherModule } from '@red/components/media-watcher';
import { RouteLoadingModule } from '@shared/components/router-loading';
import { TranslocoRootModule } from './transloco-root.module';
import { LayoutModule } from '@id/ui/layout';
import { AuthService } from '@id/auth/services';
import { BroadcastChannelModule } from '@shared/components/broadcast-channel';

@NgModule({
  declarations: [AppComponent, NxWelcomeComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,

    BrowserAnimationsModule,
    HttpClientModule,
    RedLayoutConfigModule.forRoot(appConfig),
    LayoutModule,
    RedIconModule.forRoot(CUSTOME_ICON_DATA),
    RedApiModule.forRoot({
      apiHost: environment.apiHost,
      urlPrefix: environment.apiPrefix,
      timeout: 90000,
      appId: environment.appId,
    }),

    ConfigModule.forRoot({
      load: [
        getAppConfigProvider(environment),
        useUserAdapterService(UserService),
        getNavigationService(NavigationPermissionService),
        getDateTimeConfigProvider(dateTimeConfig),
        ...getDateTimeAdapterProvider({ adapter: DateFnsDateAdapter, format: APP_DATE_FORMATS }),
      ],
    }),
    AuthModule.forSSRFeature({
      serviceInstance: useUathApiLoader(AuthService),
      loginRoute: ROUTE.AUTH.LOGIN,
      logoutRoute: ROUTE.AUTH.LOGOUT,
      forcedLogoutFn: forcedLogoutFn,
      customAuthInterceptor: true,
    }),
    TranslocoModuleCore.forRoot({ prodMode: environment.production }),
    RedMediaWatcherModule,
    RouteLoadingModule,
    BroadcastChannelModule.forRoot({
      channelNames: ['appState'],
    }),
  ],

  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
