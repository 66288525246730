import { compactNavigation, defaultNavigation } from '@agent-portal/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationItem } from '@red/components/navigation';
import { NavigationBaseService, NavigationGroup } from '@shared/components/layout';
import { cloneDeep } from 'lodash-es';
import { Observable, of, ReplaySubject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends NavigationBaseService {
  protected readonly _compactNavigation: NavigationItem[] = compactNavigation;
  protected readonly _defaultNavigation: NavigationItem[] = defaultNavigation;

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  get(): Observable<NavigationGroup> {
    // Fill compact navigation children using the default navigation
    this._compactNavigation.forEach(compactNavItem => {
      this._defaultNavigation.forEach(defaultNavItem => {
        if (defaultNavItem.id === compactNavItem.id) {
          compactNavItem.children = cloneDeep(defaultNavItem.children);
        }
      });
    });
    return of({
      compact: cloneDeep(this._compactNavigation),
      default: cloneDeep(this._defaultNavigation),
    }).pipe(
      tap(navigation => {
        this._navigation.next(navigation);
      })
    );
  }
}
