import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { InjectionToken, Provider, Type, ValueProvider } from '@angular/core';
import { enUS } from 'date-fns/locale';

export interface IDateTimeConfig {
  date: string;
  shortDate: string;
  time: string;
  dateTime: string;
}

export const DATE_TIME_CONFIG = new InjectionToken<IDateTimeConfig>('agent-portal.date-time-config');

export const getDateTimeConfigProvider = (value?: IDateTimeConfig): ValueProvider => ({
  provide: DATE_TIME_CONFIG,
  useValue: value,
});

export const getDateTimeAdapterProvider = (configs: { adapter: Type<DateAdapter<any>>; format: MatDateFormats }): Provider[] => [
  {
    provide: DateAdapter,
    useClass: configs.adapter,
  },
  {
    provide: MAT_DATE_FORMATS,
    useValue: configs.format,
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: enUS,
  },
];
