import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationItem } from '@red/components/navigation';
import { NavigationGroup } from '@shared/components/layout';
import { Permission, PermissionsService } from '@shared/permission';
import { cloneDeep } from 'lodash-es';
import { combineLatest, map, Observable, of, ReplaySubject, Subject, tap } from 'rxjs';
import { NavigationService } from './navigation.service';

export class NavigationItemFlatNode {
  item!: NavigationItem;
  level!: number;
  hidden!: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationPermissionService extends NavigationService {
  protected _permissions: Subject<Permission[]> = new Subject<Permission[]>();
  protected _fullNavigation: ReplaySubject<NavigationItem[]> = new ReplaySubject<NavigationItem[]>(1);

  private flatNodeMap = new Map<string, NavigationItemFlatNode>();
  constructor(protected override _httpClient: HttpClient, private _permissionService: PermissionsService) {
    super(_httpClient);
    this.init();
  }
  private init(): void {
    combineLatest({
      permissions: this._permissionService.permissions$,
      navigations: this._fullNavigation,
    })
      .pipe(
        tap(({ permissions, navigations }) => {
          this.flatNodeMap.clear();
          this.initFlatTree(navigations);
        }),
        map(({ permissions, navigations }) => {
          const navigatioFiltered: NavigationItem[] = this.buildNestedTree(navigations);

          return navigatioFiltered;
        }),
        map(defaultNavigation => {
          // console.log('defaultNavigation -->',defaultNavigation,this.flatNodeMap)
          this._compactNavigation.forEach(compactNavItem => {
            defaultNavigation.forEach(defaultNavItem => {
              if (defaultNavItem.id === compactNavItem.id) {
                compactNavItem.hidden = defaultNavItem.hidden;
                compactNavItem.children = cloneDeep(defaultNavItem.children);
              }
            });
          });
          return {
            compact: cloneDeep(this._compactNavigation),
            default: cloneDeep(defaultNavigation),
          };
        })
      )
      .subscribe(res => {
        this._navigation.next(res);
      });
  }
  private initFlatTree(navigations: NavigationItem[], level = 0): void {
    navigations.forEach(node => {
      if (!node.id) {
        throw new Error('id is not null');
      }
      const existingNode = this.flatNodeMap.get(node.id);
      // if (existingNode) {
      //   throw new Error(`id ${node.id} is duplicated`);
      // }
      if (!existingNode) {
        const flatNode = new NavigationItemFlatNode();
        flatNode.item = node;
        flatNode.level = level;
        flatNode.hidden = !node.meta?.permission?.disabled && this.isHidden(node);
        this.flatNodeMap.set(node.id, flatNode);
        if (node.children) {
          this.initFlatTree(node.children, level + 1);
        }
      }
    });
  }
  private buildNestedTree(navigations: NavigationItem[]): NavigationItem[] {
    navigations.forEach(node => {
      this.initPermissionFn(node);
      if (node.children) {
        node.children = this.buildNestedTree(node.children);
      }
    });
    return navigations;
  }
  private initPermissionFn(navigation: NavigationItem): NavigationItem {
    navigation.hidden = cur => {
      if (!cur.id) {
        return true;
      }
      return this.flatNodeMap.get(cur.id)?.hidden || false;
    };
    return navigation;
  }
  private isHidden(navigation: NavigationItem): boolean {
    if (navigation.children) {
      return navigation.children.every(child => this.isHidden(child));
    }
    return !coerceBooleanProperty(navigation.meta?.permission?.disabled) && this._permissionService.canDeny(navigation.meta?.permission?.slug || '');
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get all navigation data
   */
  override get(): Observable<NavigationGroup> {
    // Fill compact navigation children using the default navigation

    return of({
      compact: [],
      default: cloneDeep(this._defaultNavigation),
    }).pipe(
      tap(() => {
        this._fullNavigation.next(this._defaultNavigation);
      })
    );
  }
}
