import { InjectionToken, ValueProvider } from '@angular/core';
import { ENV_TYPE, STORAGE_TYPE } from '@red/data-access';

export interface IAppConfig {
  production: boolean;
  baseUrl: string;
  apiHost: string;
  storageMediaUrl: string;
  storageDrive: STORAGE_TYPE;
  apiPrefix: string; // '/api/'
  appId: string;
  defaultTimeZone: string; // 'Asia/Singapore',
  name: ENV_TYPE;
  redocRedirectUri: string;
  hipUrl: string
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('agent-portal.app-config');

export const getAppConfigProvider = (value: IAppConfig): ValueProvider => ({
  provide: APP_CONFIG,
  useValue: value,
});
