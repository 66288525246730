import { defaults, isFunction, assign } from 'lodash-es';
import 'reflect-metadata';
import { take } from 'rxjs/operators';
import { ConfirmDialogData, ConfirmDialogType } from './confirm-dialog-data.interface';
import { ConfirmDialogModule } from './confirm-dialog.module';
import { ConfirmDialogService } from './confirm-dialog.service';
const optionDefault: { [x in ConfirmDialogType]: ConfirmDialogData } = {
  success: {
    type: 'success',
    title: 'Success',
    description: 'successfully',
  },
  danger: {
    type: 'danger',
    title: 'Error',
    description: 'Fail',
  },
  warning: {
    type: 'warning',
    title: 'Warning',
  },
};

const confirmResKey = Symbol('confirmRes');
export function ConfirmRes() {
  return function (target: object, propertyKey: string | symbol, parameterIndex: number) {
    const confirmResKeyParameters = Reflect.getOwnMetadata(confirmResKey, target, propertyKey);
    // existingRequiredParameters.push(parameterIndex);
    if (typeof confirmResKeyParameters === 'number') {
      throw new Error(`ConfirmRes is existed in this function`);
    }
    Reflect.defineMetadata(confirmResKey, parameterIndex, target, propertyKey);
  };
}
export function Confirmable(optionsFn?: ConfirmDialogData | ((...params: any[]) => ConfirmDialogData)) {
  return function (target: object, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    // the usual, caching the original implementation
    const originalMethod = descriptor.value;
    // default values for our config, we’ll overwrite this with our options parameter

    descriptor.value = function (...args: any[]): any {
      if (!ConfirmDialogModule?.injector) {
        throw new Error(`NullInjectorError: No provider for ConfirmDialogService!. Let inject ConfirmDialogModule in this module`);
      }
      const service = ConfirmDialogModule?.injector?.get(ConfirmDialogService);
      const options = optionsFn && isFunction(optionsFn) ? optionsFn(...args) : optionsFn;
      const normalizedOptions = assign(optionDefault[options?.type || 'success'], options);
      const dialogRef = service ? service.open(normalizedOptions) : null;
      if (dialogRef) {
        return dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(confirmed => {
            const confirmResKeyParameterIndex = Reflect.getOwnMetadata(confirmResKey, target, propertyKey);
            if (!confirmed && typeof confirmResKeyParameterIndex !== 'number') {
              return;
            }
            args[confirmResKeyParameterIndex] = confirmed;
            originalMethod.apply(this, args);
          });
      }
    };
    return descriptor;
  };
}
