import { ENV_TYPE, STORAGE_TYPE } from '@red/data-access';
import { REDOC_CONNECT_RESPONSE_TYPE } from '@shared/data-access/enums';

export const environment = {
  baseUrl: 'https://huttons-id-dev.redoc.co',
  production: false,
  apiHost: 'https://huttons-api-gateway-dev.azure-api.net',
  storageMediaUrl: 'https://huttonsstoragedev.blob.core.windows.net',
  storageDrive: STORAGE_TYPE.AZURE,
  apiPrefix: '/api/',
  appId: 'default',
  defaultTimeZone: 'Asia/Singapore',
  name: ENV_TYPE.DEV,
  // redocRedirectUri: 'https://agent-dev.redoc.co/auth/hutton-login',
  redocRedirectUri: 'https://agent-dev.redoc.co/document',
  ingeniqueApiConfig: {
    token:
      'eyJhbGciOiJIUzUxMiJ9.eyJhY2NvdW50SWQiOiIzMDBsaXZlIiwiaWF0IjoxNjcwNTY4ODc4LCJ1c2VySWQiOiI5MDkifQ.hpDdfxfmSKI7YB8yYBUz0pj-ONNJxoyyTqks6nxib9WHF1kTzbf97mE8OXOvrhNWOBbrfDqiI86Ecyx4-ZPBAw',
    apiKey: 'oo7KUatYqm3iLra6MhLta2BAeh6lIpUH9aoBTXg8',
    sectorId: 'SGDJ',
    accountName: '300live',
  },
  authConfig: {
    sessionPrefix: 'dev',
    domain: '.redoc.co',
    defaultAppUrl: 'https://huttons-portal-dev.redoc.co',
  },
  hipUrl: ''
};
