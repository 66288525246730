import { Component, OnDestroy, OnInit } from '@angular/core';
import { BroadcastChannelService } from '@shared/components/broadcast-channel';
import { AuthProvider, AuthActionEvent } from '@shared/features/auth';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'id-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'identity';
  private _unsubscribeAll: Subject<void> = new Subject<void>();
  constructor(private _authService: AuthProvider, private _broadcast: BroadcastChannelService) {}
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
  ngOnInit(): void {
    this._authService.actionEvent.pipe(takeUntil(this._unsubscribeAll)).subscribe(event => {
      this._broadcast.dispatch<AuthActionEvent>({
        channelName: 'appState',
        type: 'authAction',
        payload: event,
      });
    });
    this._broadcast
      .on<AuthActionEvent>('appState', 'authAction')
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        location.reload();
      });
  }
}
