import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog-data.interface';

@Component({
  selector: 'red-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  confirmData!: { link?: string; text: string }
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {
    this.confirmData = typeof data.confirmLabel === 'string' ? { text: data.confirmLabel } : (data.confirmLabel || { text: 'OK' })
  }

  confirm(): void {
    this.dialogRef.close(true);
  }
  dimiss(): void {
    this.dialogRef.close(false);
  }
}
