import { IDesignation } from './designation.interface';

export interface ISplitPart {
  level: number;
  name?: string;
  percentage: number;
  value?: number;
  salesperson?: {
    id: number;
    businessName: string;
    nricName: string;
    ceaRegNo: string;
  };
  salespersonId?: number;
  defaultValue?: number;
}

export type ISplitPartFull = ISplitPart & {
  value: number;
};

export interface IProfitSplitPart extends ISplitPart {
  designation?: IDesignation;
  designationId?: number;
}

export interface ISplitPartDto {
  name: string;
  percentage: number;
  level: number;
}
